import join from 'lodash/join';
import map from 'lodash/map';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';

export const contentCells = [
  {
    title: 'Name',
    minWidth: 120,
    displayPriority: 1,
    columnRatio: 1,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: crm.users.detail.account,
        params: {
          userUuid: result.user_uuid,
        },
      },
      linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
      text: `${result.user_first_name} ${result.user_last_name}`,
    }),
  },
  {
    attributeName: 'user_status',
    title: 'User status',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 120,
  },
  {
    attributeName: 'user_roles',
    title: 'User roles',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 120,
    transformValue: roles => join(map(roles, 'display_name'), ', '),
  },
  {
    attributeName: 'employee_status',
    title: 'Employee status',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 120,
  },
];

export const tableFacets = [
  {
    type: 'refinementList',
    props: {
      title: 'User status',
      attributeName: 'user_status',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Employee status',
      attributeName: 'employee_status',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'User role',
      attributeName: 'user_roles.display_name',
    },
  },
];
