var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": "", "data-test-id": "add_employee-modal" },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("MuiAlgoliaSelect", {
            staticClass: "w-100",
            attrs: {
              name: "users",
              index: _vm.ALGOLIA_INDEXES.users,
              title: (result) =>
                `${result.first_name} ${result.last_name} - ${result.email}`,
              label: _vm.$t("CRM.Companies.Employees.add.inputLabel"),
              placeholder: "Select user",
              "path-value": "id",
              "data-test-id": "user_id-select",
            },
            model: {
              value: _vm.userId,
              callback: function ($$v) {
                _vm.userId = $$v
              },
              expression: "userId",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex align-content-center justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c(
            "SaveButton",
            {
              attrs: {
                disabled: !_vm.userId,
                loading: _vm.employeeStatus.LOADING,
                "data-test-id": "save-button",
              },
              on: { click: _vm.addEmployee },
            },
            [_vm._v(" Add ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }