<script>
import { mapMutations, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import AddEmployeeModal from './AddEmployeeModal/AddEmployeeModal';
import EditEmployeeModal from './EditEmployeeModal/EditEmployeeModal';
import DeleteEmployeeModal from './DeleteEmployeeModal/DeleteEmployeeModal';
import ImportEmployeesModal from './ImportEmployeesModal/ImportEmployeesModal';
import { contentCells, tableFacets } from './contentCells';
import { COMPANY_SCOPES } from '../../store/CompanyModule';

export default {
  name: 'CompanyEmployeesView',
  components: {
    MuiAlgoliaList,
    AddEmployeeModal,
    DeleteEmployeeModal,
    EditEmployeeModal,
    ImportEmployeesModal,
  },
  data() {
    return {
      isAddEmployeeModalOpen: false,
      isDeleteModalOpen: false,
      isEditModalOpen: false,
      isImportEmployeesModalOpen: false,
      removableEmployeeId: undefined,
      editableUserId: undefined,
      editableUserUuid: undefined,
      editableUserStatusId: undefined,
      editableUserRoles: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    tableFilter() {
      return `company_uuid:${this.company.uuid}`;
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.tableFacets = tableFacets;
    this.algoliaItemActions = [
      {
        label: this.$t('Common.Actions.edit'),
        method: result => {
          this.isEditModalOpen = true;
          this.editableUserId = result.user_id;
          this.editableUserUuid = result.user_uuid;
          this.editableUserStatusId = result.status_id;
          this.editableUserRoles = result.user_roles;
        },
      },
      {
        label: this.$t('Common.Actions.delete'),
        type: 'danger',
        method: ({ id }) => {
          this.removableEmployeeId = id;
          this.isDeleteModalOpen = true;
        },
      },
    ];
  },
  methods: {
    getNoResultsLabel,
    ...mapMutations(DOMAINS_MODEL.app.messages.errors, [
      'throwError',
    ]),
    refreshAlgoliaStore,
    closeModals() {
      this.refreshAlgoliaStore(this.$refs.employeesTable);
      this.isAddEmployeeModalOpen = false;
      this.isEditModalOpen = false;
      this.isDeleteModalOpen = false;
      this.isImportEmployeesModalOpen = false;
    },
  },
};
</script>
<template>
  <div
    class="CompanyEmployeesView"
    data-test-id="employees-tab"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1>
        Employees
      </h1>
      <div>
        <ui-button
          :face="FACES.outline"
          class="mr-3"
          data-test-id="import-button"
          @clickbutton="isImportEmployeesModalOpen = true"
        >
          Import list of Employees
        </ui-button>
        <ui-button
          data-test-id="create-button"
          @clickbutton="isAddEmployeeModalOpen = true"
        >
          Create employee
        </ui-button>
      </div>
    </div>
    <MuiAlgoliaList
      ref="employeesTable"
      :index="ALGOLIA_INDEXES.employees"
      :table-config="contentCells"
      :item-actions="algoliaItemActions"
      :facets="tableFacets"
      :filters="tableFilter"
      :empty-message="getNoResultsLabel('employees')"
      data-test-id="algolia-table"
      is-export-enabled
    />
    <AddEmployeeModal
      v-if="isAddEmployeeModalOpen"
      :company-id="company.id"
      data-test-id="add-modal"
      @close-modal="closeModals"
    />
    <DeleteEmployeeModal
      v-if="isDeleteModalOpen"
      :employee-id="removableEmployeeId"
      :company-id="company.id"
      data-test-id="delete-modal"
      @close-modal="closeModals"
    />
    <EditEmployeeModal
      v-if="isEditModalOpen"
      :user-id="editableUserId"
      :user-uuid="editableUserUuid"
      :status-id="editableUserStatusId"
      :roles="editableUserRoles"
      data-test-id="edit-modal"
      @close-modal="closeModals"
    />
    <ImportEmployeesModal
      v-if="isImportEmployeesModalOpen"
      :company-uuid="company.uuid"
      data-test-id="import-modal"
      @close-modal="closeModals"
    />
  </div>
</template>
