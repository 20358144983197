<script>
import map from 'lodash/map';
import { MuiAlgoliaSelect, MuiModal } from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import { mapActions, mapMutations, mapState } from 'vuex';
import { SIZES } from '@emobg/vue-base';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, SaveButton } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { USER_PROFILE_TYPES } from '../constants';

export default {
  components: {
    MuiModal,
    MuiAlgoliaSelect,
    CancelButton,
    SaveButton,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
    roles: {
      type: Array,
      default: () => ([]),
    },
    userUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userStatusId: undefined,
      userRoles: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.employees, {
      employeeStatus: state => state.employees.STATUS,
    }),
  },
  created() {
    this.userStatusId = this.statusId;
    this.userRoles = map(this.roles, role => role.name);
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.modalConfig = {
      title: this.$t('CRM.Companies.Employees.edit.title'),
      size: SIZES.small,
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    sentenceCase,
    ...mapActions(DOMAINS_MODEL.crm.employees, [
      'putEmployeeStatus',
      'putUserRoles',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async editEmployee() {
      const statusRequest = {
        userId: this.userId,
        data: {
          businessStatusId: this.userStatusId,
        },
      };
      const rolesRequest = {
        userUuid: this.userUuid,
        data: {
          user_profile_type: USER_PROFILE_TYPES.business,
          roles: this.userRoles,
        },
      };
      await Promise.all([
        this.putEmployeeStatus(statusRequest),
        this.putUserRoles(rolesRequest),
      ]);
      if (!this.employeeStatus.ERROR) {
        this.notify({ message: this.$t('CRM.Companies.Employees.edit.successMessage') });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <MuiModal
    v-bind="modalConfig"
    is-open
    data-test-id="edit_employee-modal"
    @modal-closed="$emit('close-modal')"
  >
    <div slot="body">
      <MuiAlgoliaSelect
        v-model="userStatusId"
        name="status"
        :index="ALGOLIA_INDEXES.status"
        :title="result => sentenceCase(result.name)"
        :label="$t('CRM.Companies.Employees.edit.statusInput')"
        filters="group:employees"
        class="w-100"
        path-value="id"
        data-test-id="user_status_id-select"
      />
      <MuiAlgoliaSelect
        v-model="userRoles"
        name="roles"
        :index="ALGOLIA_INDEXES.roles"
        :title="result => sentenceCase(result.name)"
        :label="$t('CRM.Companies.Employees.edit.rolesInput')"
        filters="group:frontoffice"
        class="w-100 mt-2"
        path-value="name"
        data-test-id="user_roles-select"
        multiple
      />
    </div>
    <div
      slot="footer"
      class="d-flex align-content-center justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <SaveButton
        :loading="employeeStatus.LOADING"
        data-test-id="save-button"
        @click="editEmployee"
      />
    </div>
  </MuiModal>
</template>
