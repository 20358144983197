import { VALIDATION_PATTERN } from '@emobg/web-utils';

export const MAX_FILE_SIZE = 3145728;

export const IMPORT_EMPLOYEE_INSTRUCTIONS = [
  {
    label: 'First name',
    column: 'First_name*',
    key: 'firstName',
    example: 'John',
    isRequired: true,
    cell: 'A1',
  },
  {
    label: 'Last name',
    column: 'Last_name*',
    key: 'lastName',
    example: 'Smith',
    isRequired: true,
    cell: 'B1',
  },
  {
    label: 'Account email',
    column: 'Account_email*',
    key: 'email',
    example: 'john.smith@email.com',
    isRequired: true,
    validator: VALIDATION_PATTERN.email,
    cell: 'C1',
  },
  {
    label: 'Notification email',
    column: 'Notification_email*',
    key: 'notificationEmail',
    example: 'john.smith@gmail.com',
    isRequired: true,
    validator: VALIDATION_PATTERN.email,
    cell: 'D1',
  },
  {
    label: 'Phone number',
    column: 'Phone_number*',
    key: 'notificationPhone',
    example: '+49 (35) 121-212-121',
    isRequired: true,
    validator: /^[0-9,+\-\s()]*$/,
    cell: 'E1',
  },
  {
    label: 'Address',
    column: 'Address*',
    key: 'address',
    example: '21 Karl Platz, 10149 Berlin, Germany',
    isRequired: true,
    cell: 'F1',
  },
];

export const errorsContentCells = [
  {
    title: 'Row',
    displayPriority: 1,
    property: 'index',
    minWidth: 30,
  },
  {
    title: 'Errors',
    displayPriority: 1,
    property: 'message',
    minWidth: 320,
    transformValue: value => `*** ${value}`,
    classes: 'emobg-color-danger',
  },
];
