var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "emobg-border-1 emobg-border-color-success mx-3 my-5 emobg-border-radius-small emobg-background-color-ground-lightest",
      attrs: { "data-test-id": "import_employees_success-view" },
    },
    [
      _c("div", { staticClass: "row p-6" }, [
        _c(
          "div",
          {
            staticClass:
              "col-sm-6 col-12 d-flex flex-column justify-content-center",
          },
          [
            _c("h4", { staticClass: "emobg-font-weight-semibold" }, [
              _vm._v(
                " Here's the processing summary of " +
                  _vm._s(_vm.numberOfEmployees) +
                  " employees "
              ),
            ]),
            _c("p", { staticClass: "mt-1" }, [
              _vm._v(" " + _vm._s(_vm.fileName) + " "),
            ]),
            _c(
              "div",
              { staticClass: "d-flex align-items-center mt-3" },
              [
                _c("ui-icon", {
                  staticClass: "mr-2",
                  attrs: {
                    icon: _vm.ICONS.bold.check,
                    size: _vm.ICONS_SIZES.xLarge,
                    color: _vm.COLORS.success,
                  },
                }),
                _c(
                  "p",
                  { staticClass: "emobg-color-success emobg-caption-1" },
                  [_vm._v(" All employees sucessfully imported ")]
                ),
              ],
              1
            ),
            _vm._m(0),
            _c(
              "ui-button",
              {
                staticClass: "mt-3",
                attrs: { "data-test-id": "accept-button" },
                on: { clickbutton: _vm.onClickUnderstood },
              },
              [_vm._v(" Understood ")]
            ),
          ],
          1
        ),
        _vm._m(1),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2" }, [
      _c("ul", [
        _c("li", { staticClass: "mt-2" }, [
          _vm._v(
            ' · Employees in file were created with the Business status "Pre-registered" '
          ),
        ]),
        _c("li", { staticClass: "mt-2" }, [
          _vm._v(" · Allow some time for them to appear in the employee list "),
        ]),
        _c("li", { staticClass: "mt-2" }, [
          _vm._v(
            " · Employees will be notified to their account email adress "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 col-12 text-center pt-6" }, [
      _c("img", {
        attrs: {
          src: require("./images/import_success.svg"),
          alt: "import employees success image",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }