var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        staticClass: "ImportEmployeesModal",
        attrs: {
          size: _vm.SIZES.large,
          "data-test-id": "import_emloyees-modal",
          "is-open": "",
        },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _vm.isSuccessView
        ? _c("ImportEmployeesModalSuccess", {
            attrs: {
              slot: "body",
              "on-click-understood": _vm.closeSuccessScreen,
              "number-of-employees": _vm.numberOfEmployees,
              "file-name": _vm.fileName,
            },
            slot: "body",
          })
        : _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6 pr-3" },
                [
                  _c("h3", { staticClass: "mb-4" }, [
                    _vm._v(" 1. Download a XLSX file template "),
                  ]),
                  _c(
                    "a",
                    {
                      attrs: {
                        download: "",
                        href: "/crm/import_employees_template.xlsx",
                      },
                    },
                    [
                      _c(
                        "ui-button",
                        {
                          attrs: {
                            face: _vm.FACES.outline,
                            "data-test-id": "download_template-button",
                          },
                        },
                        [_vm._v(" Download template ")]
                      ),
                    ],
                    1
                  ),
                  _c("h3", { staticClass: "my-4" }, [
                    _vm._v(
                      " 2. Fill in the XLSX file template as in the example below "
                    ),
                  ]),
                  _c("div", { staticClass: "row pb-1 emobg-font-x-small" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-4 mb-2 emobg-font-weight-semibold",
                      },
                      [_vm._v(" Columns ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 emobg-font-weight-semibold" },
                      [_vm._v(" Content example ")]
                    ),
                  ]),
                  _vm._l(
                    _vm.IMPORT_EMPLOYEE_INSTRUCTIONS,
                    function (instruction, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "row mb-1 emobg-font-x-small",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-4 emobg-color-ink" },
                            [
                              _c(
                                "strong",
                                { staticClass: "emobg-font-weight-semibold" },
                                [_vm._v(_vm._s(instruction.cell))]
                              ),
                              _vm._v(" " + _vm._s(instruction.column) + " "),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-8 emobg-color-ink-light" },
                            [_vm._v(" " + _vm._s(instruction.example) + " ")]
                          ),
                        ]
                      )
                    }
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "emobg-font-x-small emobg-color-ink-light my-4",
                    },
                    [
                      _vm._v(
                        " (All the other columns such as G1, H1, I1... can be used for cost allocations) "
                      ),
                    ]
                  ),
                  _c("img", {
                    staticClass:
                      "w-100 emobg-border-1 emobg-border-color-ground-light emobg-border-radius-small",
                    attrs: {
                      src: require("./images/import_employees_example.png"),
                      alt: "excel example",
                    },
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "col-6 pl-3" }, [
                _vm.isUploadView && !_vm.isProcessing
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "h-100 position-relative d-flex flex-column",
                      },
                      [
                        _c("h3", [
                          _vm._v("3. Upload the filled-in XLSX file template"),
                        ]),
                        _c(
                          "ui-alert",
                          {
                            staticClass: "w-100 animated fadeIn my-3 pb-1",
                            attrs: {
                              color: _vm.COLORS.primary,
                              icon: _vm.ICONS.infoFull,
                            },
                          },
                          [
                            _c("p", { staticClass: "emobg-caption-4" }, [
                              _c(
                                "b",
                                {
                                  staticClass:
                                    "emobg-caption-4 emobg-font-weight-bold",
                                },
                                [_vm._v("If uploaded file has NO errors:")]
                              ),
                              _vm._v(
                                " All employees get immediately created & notified. "
                              ),
                            ]),
                            _c("p", { staticClass: "emobg-caption-4" }, [
                              _c(
                                "b",
                                {
                                  staticClass:
                                    "emobg-caption-4 emobg-font-weight-bold",
                                },
                                [_vm._v("If uploaded file has errors:")]
                              ),
                              _vm._v(
                                " No employees get created at all. Errors will be listed. "
                              ),
                            ]),
                          ]
                        ),
                        _vm.isUploadView
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ImportEmployeesModal__dropArea h-100 px-3 d-flex align-items-center justify-content-center emobg-border-1 emobg-border-color-ground-light emobg-background-color-ground-lightest emobg-border-radius-small",
                              },
                              [
                                _c("DragFile", {
                                  ref: "dragInput",
                                  on: {
                                    dragOver: (value) =>
                                      (_vm.isDragOver = value),
                                    file: _vm.onFileUpload,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-center justify-content-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-center mb-2" },
                                      [
                                        _c("ui-icon", {
                                          attrs: {
                                            icon: _vm.ICONS.upload,
                                            size: _vm.ICONS_SIZES.medium,
                                            color: _vm.GRAYSCALE.inkLight,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "emobg-color-ink-light mr-1",
                                          },
                                          [_vm._v(" Drop your file here or ")]
                                        ),
                                        _c(
                                          "ui-button",
                                          {
                                            staticClass:
                                              "ImportEmployeesModal__textButton",
                                            attrs: {
                                              face: _vm.FACES.text,
                                              "data-test-id":
                                                "upload_file-button",
                                            },
                                            on: {
                                              clickbutton: _vm.openFileSelector,
                                            },
                                          },
                                          [_vm._v(" Browse ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isErrorsView || _vm.isProcessing
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "h-100 position-relative d-flex flex-column",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "emobg-font-weight-light mb-3" },
                          [
                            _vm._v(
                              " 3. Upload the filled-in XLSX file template "
                            ),
                          ]
                        ),
                        _vm.isProcessing
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ImportEmployeesModal__dropArea h-100 px-3 d-flex align-items-center justify-content-center emobg-border-color-primary emobg-background-color-primary-lightest emobg-border-radius-small",
                              },
                              [
                                _c("ui-loader", {
                                  staticClass: "pr-5",
                                  attrs: {
                                    color: _vm.COLORS.primary,
                                    "data-test-id": "activating-loader",
                                    label: "Importing file",
                                  },
                                }),
                              ],
                              1
                            )
                          : [
                              !_vm.isEmpty(_vm.tableErrors)
                                ? _c("ImportEmployeesModalErrorTable", {
                                    attrs: {
                                      "file-name": _vm.fileName,
                                      errors: _vm.tableErrors,
                                      "number-of-employees":
                                        _vm.numberOfEmployees,
                                      "on-upload-again-click":
                                        _vm.openFileSelector,
                                      "on-click-download-errors":
                                        _vm.downloadErrorsSummary,
                                    },
                                  })
                                : _c("ImportEmployeesGenericError", {
                                    attrs: {
                                      error: _vm.genericError,
                                      "on-upload-again-click":
                                        _vm.openFileSelector,
                                    },
                                  }),
                              _c("DragFile", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isSuccessView,
                                    expression: "isSuccessView",
                                  },
                                ],
                                ref: "dragInput",
                                on: {
                                  dragOver: (value) => (_vm.isDragOver = value),
                                  file: _vm.onFileUpload,
                                },
                              }),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }