<script>
export default {
  name: 'ImportEmployeesModalSuccess',
  props: {
    numberOfEmployees: {
      type: Number,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    onClickUnderstood: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<template>
  <div
    class="emobg-border-1 emobg-border-color-success mx-3 my-5 emobg-border-radius-small emobg-background-color-ground-lightest"
    data-test-id="import_employees_success-view"
  >
    <div class="row p-6">
      <div class="col-sm-6 col-12 d-flex flex-column justify-content-center">
        <h4 class="emobg-font-weight-semibold">
          Here's the processing summary of {{ numberOfEmployees }} employees
        </h4>
        <p class="mt-1">
          {{ fileName }}
        </p>
        <div class="d-flex align-items-center mt-3">
          <ui-icon
            :icon="ICONS.bold.check"
            :size="ICONS_SIZES.xLarge"
            :color="COLORS.success"
            class="mr-2"
          />
          <p class="emobg-color-success emobg-caption-1">
            All employees sucessfully imported
          </p>
        </div>
        <div class="mt-2">
          <ul>
            <li class="mt-2">
              · Employees in file were created with the Business status "Pre-registered"
            </li>
            <li class="mt-2">
              · Allow some time for them to appear in the employee list
            </li>
            <li class="mt-2">
              · Employees will be notified to their account email adress
            </li>
          </ul>
        </div>
        <ui-button
          class="mt-3"
          data-test-id="accept-button"
          @clickbutton="onClickUnderstood"
        >
          Understood
        </ui-button>
      </div>
      <div class="col-sm-6 col-12 text-center pt-6">
        <img
          src="./images/import_success.svg"
          alt="import employees success image"
        >
      </div>
    </div>
  </div>
</template>
