var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "emobg-background-color-ground-lightest emobg-border-1 emobg-border-color-ink-lighter p-3 emobg-border-radius-small h-100",
    },
    [
      _c("h5", { staticClass: "mb-1 emobg-font-weight-semibold" }, [
        _vm._v(
          " Here's the processing summary of " +
            _vm._s(_vm.numberOfEmployees) +
            " employees "
        ),
      ]),
      _c(
        "p",
        { staticClass: "emobg-font-weight-light mb-2 emobg-font-x-small" },
        [_vm._v(" " + _vm._s(_vm.fileName) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-3 d-flex align-items-center" },
        [
          _c("ui-icon", {
            staticClass: "mr-2",
            attrs: {
              icon: _vm.ICONS.alertFull,
              size: _vm.ICONS_SIZES.xLarge,
              color: _vm.COLORS.danger,
            },
          }),
          _c("p", { staticClass: "emobg-color-danger emobg-caption-2" }, [
            _vm._v(" " + _vm._s(_vm.errors.length) + " employees have errors "),
          ]),
        ],
        1
      ),
      _c(
        "ui-alert",
        {
          staticClass: "w-100 animated fadeIn pb-3",
          attrs: { color: _vm.COLORS.danger },
        },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "emobg-font-weight-semibold emobg-font-line-height-large",
                },
                [_vm._v(" We cannot import this file ")]
              ),
              _c("p", { staticClass: "emobg-font-line-height-large" }, [
                _vm._v(
                  " Correct the errors first and then upload the file again. "
                ),
              ]),
              _c("p", { staticClass: "emobg-font-line-height-large" }, [
                _vm._v(
                  " You can download the XSLX file with the errors below. "
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "w-100 d-flex justify-content-center" },
        [
          _c("MuiTable", {
            staticClass: "ImportEmployeesModal__errorTable w-100 mt-1",
            attrs: {
              "content-cells": _vm.contentCells,
              "data-set": _vm.errors,
              "no-data-label": _vm.FALLBACK_MESSAGE.dash,
              "data-test-id": "import_employee_error-table",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex my-3" },
        [
          _c(
            "ui-button",
            {
              staticClass: "mr-3",
              attrs: { face: _vm.FACES.outline },
              on: { clickbutton: _vm.onClickDownloadErrors },
            },
            [_vm._v(" Download XSLX with errors ")]
          ),
          _c("ui-button", { on: { clickbutton: _vm.onUploadAgainClick } }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _vm._v(" Upload again "),
                _c("ui-icon", {
                  staticClass: "ml-2",
                  attrs: {
                    icon: _vm.ICONS.upload,
                    size: _vm.ICONS_SIZES.medium,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "emobg-caption-1 mb-1" }, [
      _c("b", { staticClass: "emobg-caption-2" }, [
        _vm._v(" If uploaded file has NO errors: "),
      ]),
      _vm._v(" All employees get immediately created & notified. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "emobg-caption-1" }, [
      _c("b", { staticClass: "emobg-caption-2" }, [
        _vm._v(" If uploaded file has errors: "),
      ]),
      _vm._v(" No employees get created at all. Errors will be listed. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }