<script>
export default {
  props: {
    error: {
      type: String,
      required: true,
    },
    onUploadAgainClick: {
      type: Function,
      required: true,
    },
  },
};
</script>
<template>
  <div
    class="
      emobg-background-color-ground-lightest emobg-border-1
      emobg-border-color-ink-lighter p-4 emobg-border-radius-small h-100
    "
  >
    <h5 class="emobg-font-weight-semibold mb-3">
      Here's the processing summary
    </h5>
    <div class="mb-3 d-flex align-items-center">
      <ui-icon
        :icon="ICONS.alertFull"
        :color="COLORS.danger"
        class="mr-1"
      />
      <p class="emobg-color-danger emobg-caption-1">
        {{ error }}
      </p>
    </div>
    <ui-alert
      :color="COLORS.danger"
      class="w-100 animated fadeIn pb-3"
    >
      <p class="emobg-font-weight-semibold">
        We cannot import this file.
      </p>
      <p>
        Correct the error and upload the file again.
      </p>
    </ui-alert>
    <ui-button
      class="mb-3"
      @clickbutton="onUploadAgainClick"
    >
      <div class="d-flex align-items-center">
        Upload again
        <ui-icon
          class="ml-2"
          :icon="ICONS.upload"
          :size="ICONS_SIZES.medium"
        />
      </div>
    </ui-button>
    <p class="emobg-caption-1 mt-3">
      <strong class="emobg-font-weight-semibold">
        If uploaded file has NO errors:
      </strong>
      All employees get immediately created &amp; notified.
    </p>
    <p class="emobg-caption-1">
      <strong class="emobg-font-weight-semibold">
        If uploaded file has errors:
      </strong> No employees get created at all. Errors will be listed.
    </p>
  </div>
</template>
