var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": "", "data-test-id": "edit_employee-modal" },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("MuiAlgoliaSelect", {
            staticClass: "w-100",
            attrs: {
              name: "status",
              index: _vm.ALGOLIA_INDEXES.status,
              title: (result) => _vm.sentenceCase(result.name),
              label: _vm.$t("CRM.Companies.Employees.edit.statusInput"),
              filters: "group:employees",
              "path-value": "id",
              "data-test-id": "user_status_id-select",
            },
            model: {
              value: _vm.userStatusId,
              callback: function ($$v) {
                _vm.userStatusId = $$v
              },
              expression: "userStatusId",
            },
          }),
          _c("MuiAlgoliaSelect", {
            staticClass: "w-100 mt-2",
            attrs: {
              name: "roles",
              index: _vm.ALGOLIA_INDEXES.roles,
              title: (result) => _vm.sentenceCase(result.name),
              label: _vm.$t("CRM.Companies.Employees.edit.rolesInput"),
              filters: "group:frontoffice",
              "path-value": "name",
              "data-test-id": "user_roles-select",
              multiple: "",
            },
            model: {
              value: _vm.userRoles,
              callback: function ($$v) {
                _vm.userRoles = $$v
              },
              expression: "userRoles",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex align-content-center justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c("SaveButton", {
            attrs: {
              loading: _vm.employeeStatus.LOADING,
              "data-test-id": "save-button",
            },
            on: { click: _vm.editEmployee },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }