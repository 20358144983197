var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyEmployeesView",
      attrs: { "data-test-id": "employees-tab" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between mb-2",
        },
        [
          _c("h1", [_vm._v(" Employees ")]),
          _c(
            "div",
            [
              _c(
                "ui-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    face: _vm.FACES.outline,
                    "data-test-id": "import-button",
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isImportEmployeesModalOpen = true
                    },
                  },
                },
                [_vm._v(" Import list of Employees ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: { "data-test-id": "create-button" },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isAddEmployeeModalOpen = true
                    },
                  },
                },
                [_vm._v(" Create employee ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("MuiAlgoliaList", {
        ref: "employeesTable",
        attrs: {
          index: _vm.ALGOLIA_INDEXES.employees,
          "table-config": _vm.contentCells,
          "item-actions": _vm.algoliaItemActions,
          facets: _vm.tableFacets,
          filters: _vm.tableFilter,
          "empty-message": _vm.getNoResultsLabel("employees"),
          "data-test-id": "algolia-table",
          "is-export-enabled": "",
        },
      }),
      _vm.isAddEmployeeModalOpen
        ? _c("AddEmployeeModal", {
            attrs: {
              "company-id": _vm.company.id,
              "data-test-id": "add-modal",
            },
            on: { "close-modal": _vm.closeModals },
          })
        : _vm._e(),
      _vm.isDeleteModalOpen
        ? _c("DeleteEmployeeModal", {
            attrs: {
              "employee-id": _vm.removableEmployeeId,
              "company-id": _vm.company.id,
              "data-test-id": "delete-modal",
            },
            on: { "close-modal": _vm.closeModals },
          })
        : _vm._e(),
      _vm.isEditModalOpen
        ? _c("EditEmployeeModal", {
            attrs: {
              "user-id": _vm.editableUserId,
              "user-uuid": _vm.editableUserUuid,
              "status-id": _vm.editableUserStatusId,
              roles: _vm.editableUserRoles,
              "data-test-id": "edit-modal",
            },
            on: { "close-modal": _vm.closeModals },
          })
        : _vm._e(),
      _vm.isImportEmployeesModalOpen
        ? _c("ImportEmployeesModal", {
            attrs: {
              "company-uuid": _vm.company.uuid,
              "data-test-id": "import-modal",
            },
            on: { "close-modal": _vm.closeModals },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }