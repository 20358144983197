var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "emobg-background-color-ground-lightest emobg-border-1 emobg-border-color-ink-lighter p-4 emobg-border-radius-small h-100",
    },
    [
      _c("h5", { staticClass: "emobg-font-weight-semibold mb-3" }, [
        _vm._v(" Here's the processing summary "),
      ]),
      _c(
        "div",
        { staticClass: "mb-3 d-flex align-items-center" },
        [
          _c("ui-icon", {
            staticClass: "mr-1",
            attrs: { icon: _vm.ICONS.alertFull, color: _vm.COLORS.danger },
          }),
          _c("p", { staticClass: "emobg-color-danger emobg-caption-1" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ]),
        ],
        1
      ),
      _c(
        "ui-alert",
        {
          staticClass: "w-100 animated fadeIn pb-3",
          attrs: { color: _vm.COLORS.danger },
        },
        [
          _c("p", { staticClass: "emobg-font-weight-semibold" }, [
            _vm._v(" We cannot import this file. "),
          ]),
          _c("p", [_vm._v(" Correct the error and upload the file again. ")]),
        ]
      ),
      _c(
        "ui-button",
        { staticClass: "mb-3", on: { clickbutton: _vm.onUploadAgainClick } },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _vm._v(" Upload again "),
              _c("ui-icon", {
                staticClass: "ml-2",
                attrs: { icon: _vm.ICONS.upload, size: _vm.ICONS_SIZES.medium },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._m(0),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "emobg-caption-1 mt-3" }, [
      _c("strong", { staticClass: "emobg-font-weight-semibold" }, [
        _vm._v(" If uploaded file has NO errors: "),
      ]),
      _vm._v(" All employees get immediately created & notified. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "emobg-caption-1" }, [
      _c("strong", { staticClass: "emobg-font-weight-semibold" }, [
        _vm._v(" If uploaded file has errors: "),
      ]),
      _vm._v(" No employees get created at all. Errors will be listed. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }