var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": "", "data-test-id": "delete_employee-modal" },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _vm._v(
          " By doing so, this user will still exist but won't be linked to this company anymore. "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex align-content-center justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: {
              color: _vm.COLORS.danger,
              loading: _vm.employeeStatus.LOADING,
              "data-test-id": "delete-button",
            },
            on: { click: _vm.removeEmployee },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }