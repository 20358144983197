<script>
import { MuiModal } from '@emobg/motion-ui/v1';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import compact from 'lodash/compact';
import upperCase from 'lodash/upperCase';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import pick from 'lodash/pick';
import set from 'lodash/set';
import flattenDeep from 'lodash/flattenDeep';
import values from 'lodash/values';
import groupBy from 'lodash/groupBy';
import join from 'lodash/join';
import forEach from 'lodash/forEach';
import { mapActions, mapState } from 'vuex';
import xlsxStyle from 'xlsx-js-style';
import moment from 'moment';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DragFile } from '@/components';
import { getSheetFromEvent, getXlsData } from '@/utils';
import { IMPORT_EMPLOYEE_INSTRUCTIONS, MAX_FILE_SIZE } from './const';
import ImportEmployeesModalErrorTable from './ImportEmployeesModalErrorTable';
import ImportEmployeesGenericError from './ImportEmployeesGenericError';
import ImportEmployeesModalSuccess from './ImportEmployeesModalSuccess';

export default {
  components: {
    MuiModal,
    DragFile,
    ImportEmployeesModalErrorTable,
    ImportEmployeesGenericError,
    ImportEmployeesModalSuccess,
  },
  props: {
    companyUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileName: '',
      fileData: [],
      isDragOver: false,
      sheetErrors: [],
      tableErrors: [],
      genericError: undefined,
      isProcessing: false,
      isUploadView: true,
      isErrorsView: false,
      isSuccessView: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.employees, {
      employeeStatus: state => state.employees.STATUS,
    }),
    numberOfEmployees() {
      return get(this.fileData, 'length', 0);
    },
  },
  created() {
    this.IMPORT_EMPLOYEE_INSTRUCTIONS = IMPORT_EMPLOYEE_INSTRUCTIONS;
    this.modalConfig = {
      title: 'Import list of employees',
      header: {
        class: 'pl-3',
        isClosable: true,
      },
      backdropDismiss: true,
    };
  },
  methods: {
    isEmpty,
    ...mapActions(DOMAINS_MODEL.crm.employees, [
      'postImportEmployees',
    ]),
    openFileSelector() {
      this.$refs.dragInput.openFileSelector();
    },
    resetErrors() {
      this.sheetErrors = [];
      this.genericError = undefined;
    },
    isFileTooBig(file) {
      return get(file, 'size', 0) > MAX_FILE_SIZE;
    },
    onFileUpload(file) {
      this.isProcessing = true;
      this.resetErrors();
      const reader = new FileReader();
      reader.onload = event => {
        const sheet = getSheetFromEvent(event);
        this.headerRow = head(sheet);
        if (this.isFileTooBig(file)) {
          this.genericError = 'Maximum file size is 3MB';
        }
        if (!this.hasMandatoryHeaders(this.headerRow, IMPORT_EMPLOYEE_INSTRUCTIONS)) {
          this.genericError = 'Columns don\'t match the template';
        }
        if (this.genericError) {
          this.isUploadView = false;
          this.isErrorsView = true;
          this.isProcessing = false;
          this.sheetErrors = [];
          this.tableErrors = [];
        } else {
          this.fileData = getXlsData(sheet, this.headerRow);
          this.sheetErrors = this.getFileErrors(this.headerRow, this.fileData, IMPORT_EMPLOYEE_INSTRUCTIONS);
          this.tableErrors = this.getTableErrors(this.sheetErrors);
          if (!isEmpty(this.sheetErrors)) {
            this.isUploadView = false;
            this.isErrorsView = true;
            this.isProcessing = false;
          } else {
            this.importEmployees();
          }
        }
      };
      if (file) {
        this.fileName = file.name;
        reader.readAsArrayBuffer(file);
      } else {
        this.genericError = 'Invalid File';
        this.isUploadView = false;
        this.isErrorsView = true;
        this.isProcessing = false;
      }
    },
    hasMandatoryHeaders(headers, instructions) {
      if (isEmpty(headers) || isEmpty(instructions)) {
        return false;
      }
      const mandatoryKeys = map(instructions, 'column');
      return isEmpty(compact(map(mandatoryKeys, key => !includes(headers, key))));
    },
    getLetterFromNumber(headers, key) {
      return upperCase(String.fromCharCode(97 + indexOf(headers, key)));
    },
    isCellValidated(value, validator) {
      return !validator || (validator && validator.test(value));
    },
    getFileErrors(headerRow, fileData, instructions) {
      const mandatoryKeys = map(instructions, 'column');
      const mandatoryData = map(fileData, row => pick(row, mandatoryKeys));
      const errorMessages = map(mandatoryData, (row, index) => map(instructions, (instruction) => {
        const value = row[instruction.column];
        const cellRow = index + 2;
        const cell = `${this.getLetterFromNumber(headerRow, instruction.column)}${cellRow}`;
        return compact([
          !value ? { cell, message: `${instruction.label} missing`, value, cellRow } : null,
          value && !this.isCellValidated(value, instruction.validator) ? { cell, message: `Wrong ${instruction.label} format`, value, cellRow } : null,
        ]);
      }));
      return flattenDeep(errorMessages);
    },
    getTableErrors(fileErrors) {
      const rowData = groupBy(fileErrors, 'cellRow');
      return map(rowData, (row, index) => ({
        index,
        message: `${join(map(row, 'message'), ', ')}.`,
      }));
    },
    closeSuccessScreen() {
      this.isSuccessView = false;
      this.$emit('close-modal');
    },
    downloadErrorsSummary() {
      const filename = moment().format('[Error_employees_list_]YYYY-MM-DD_HH[h]mm[m]SS[s.xlsx]');
      const workbook = xlsxStyle.utils.book_new();
      xlsxStyle.utils.book_append_sheet(workbook, xlsxStyle.utils.aoa_to_sheet(this.getCsvContent()), 'Sheet1');
      forEach(this.sheetErrors, ({ cell, message, value }) => {
        set(workbook, `Sheets.Sheet1.${cell}.s.font.color.rgb`, '#FF0000');
        if (!value) {
          set(workbook, `Sheets.Sheet1.${cell}.v`, message);
        }
      });
      xlsxStyle.writeFile(workbook, filename);
    },
    getCsvContent() {
      const rows = map(this.fileData, values);
      return [this.headerRow, ...rows];
    },
    async importEmployees() {
      const users = map(this.fileData, employee => {
        const mandatoryKeys = map(IMPORT_EMPLOYEE_INSTRUCTIONS, 'column');
        const mandatoryFields = merge(...map(IMPORT_EMPLOYEE_INSTRUCTIONS, ({ key, column }) => ({ [key]: employee[column] })));
        const costAllocation = omit(employee, mandatoryKeys);
        return {
          ...mandatoryFields,
          costAllocation,
        };
      });
      await this.postImportEmployees({ companyUuid: this.companyUuid, data: { users } });
      if (!this.employeeStatus.ERROR) {
        this.isSuccessView = true;
        this.isUploadView = false;
        this.isErrorsView = false;
        this.isProcessing = false;
      }
    },
  },
};
</script>
<template>
  <MuiModal
    v-bind="modalConfig"
    :size="SIZES.large"
    data-test-id="import_emloyees-modal"
    class="ImportEmployeesModal"
    is-open
    @modal-closed="$emit('close-modal')"
  >
    <ImportEmployeesModalSuccess
      v-if="isSuccessView"
      slot="body"
      :on-click-understood="closeSuccessScreen"
      :number-of-employees="numberOfEmployees"
      :file-name="fileName"
    />
    <div
      v-else
      slot="body"
    >
      <div class="row">
        <div class="col-6 pr-3">
          <h3 class="mb-4">
            1. Download a XLSX file template
          </h3>
          <a
            download
            href="/crm/import_employees_template.xlsx"
          >
            <ui-button
              :face="FACES.outline"
              data-test-id="download_template-button"
            >
              Download template
            </ui-button>
          </a>
          <h3 class="my-4">
            2. Fill in the XLSX file template as in the example below
          </h3>
          <div class="row pb-1 emobg-font-x-small">
            <div class="col-md-4 mb-2 emobg-font-weight-semibold">
              Columns
            </div>
            <div class="col-md-8 emobg-font-weight-semibold">
              Content example
            </div>
          </div>
          <div
            v-for="(instruction, index) in IMPORT_EMPLOYEE_INSTRUCTIONS"
            :key="index"
            class="row mb-1 emobg-font-x-small"
          >
            <div class="col-md-4 emobg-color-ink">
              <strong class="emobg-font-weight-semibold">{{ instruction.cell }}</strong>
              {{ instruction.column }}
            </div>
            <div class="col-md-8 emobg-color-ink-light">
              {{ instruction.example }}
            </div>
          </div>
          <div class="emobg-font-x-small emobg-color-ink-light my-4">
            (All the other columns such as G1, H1, I1... can be used for cost allocations)
          </div>
          <img
            class="w-100 emobg-border-1 emobg-border-color-ground-light emobg-border-radius-small"
            src="./images/import_employees_example.png"
            alt="excel example"
          >
        </div>
        <div class="col-6 pl-3">
          <div
            v-if="isUploadView && !isProcessing"
            class="h-100 position-relative d-flex flex-column"
          >
            <h3>3. Upload the filled-in XLSX file template</h3>
            <ui-alert
              :color="COLORS.primary"
              :icon="ICONS.infoFull"
              class="w-100 animated fadeIn my-3 pb-1"
            >
              <p class="emobg-caption-4">
                <b class="emobg-caption-4 emobg-font-weight-bold">If uploaded file has NO errors:</b>
                All employees get immediately created &amp; notified.
              </p>
              <p class="emobg-caption-4">
                <b class="emobg-caption-4 emobg-font-weight-bold">If uploaded file has errors:</b>
                No employees get created at all. Errors will be listed.
              </p>
            </ui-alert>
            <div
              v-if="isUploadView"
              class="
                ImportEmployeesModal__dropArea h-100 px-3 d-flex align-items-center justify-content-center
                emobg-border-1 emobg-border-color-ground-light emobg-background-color-ground-lightest
                emobg-border-radius-small
              "
            >
              <DragFile
                ref="dragInput"
                @dragOver="value => isDragOver = value"
                @file="onFileUpload"
              />
              <div class="d-flex flex-column align-items-center justify-content-center">
                <div class="text-center mb-2">
                  <ui-icon
                    :icon="ICONS.upload"
                    :size="ICONS_SIZES.medium"
                    :color="GRAYSCALE.inkLight"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <div class="emobg-color-ink-light mr-1">
                    Drop your file here or
                  </div>
                  <ui-button
                    :face="FACES.text"
                    data-test-id="upload_file-button"
                    class="ImportEmployeesModal__textButton"
                    @clickbutton="openFileSelector"
                  >
                    Browse
                  </ui-button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isErrorsView || isProcessing"
            class="h-100 position-relative d-flex flex-column"
          >
            <h4 class="emobg-font-weight-light mb-3">
              3. Upload the filled-in XLSX file template
            </h4>
            <div
              v-if="isProcessing"
              class="
                ImportEmployeesModal__dropArea h-100 px-3 d-flex align-items-center justify-content-center
                emobg-border-color-primary emobg-background-color-primary-lightest
                emobg-border-radius-small
              "
            >
              <ui-loader
                :color="COLORS.primary"
                data-test-id="activating-loader"
                label="Importing file"
                class="pr-5"
              />
            </div>
            <template v-else>
              <ImportEmployeesModalErrorTable
                v-if="!isEmpty(tableErrors)"
                :file-name="fileName"
                :errors="tableErrors"
                :number-of-employees="numberOfEmployees"
                :on-upload-again-click="openFileSelector"
                :on-click-download-errors="downloadErrorsSummary"
              />
              <ImportEmployeesGenericError
                v-else
                :error="genericError"
                :on-upload-again-click="openFileSelector"
              />
              <DragFile
                v-show="isSuccessView"
                ref="dragInput"
                @dragOver="value => isDragOver = value"
                @file="onFileUpload"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </MuiModal>
</template>
