<script>
import { MuiTable } from '@emobg/motion-ui/v1';
import { errorsContentCells } from './const';
export default {
  components: {
    MuiTable,
  },
  props: {
    errors: {
      type: Array,
      default: () => ([]),
    },
    onUploadAgainClick: {
      type: Function,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    numberOfEmployees: {
      type: Number,
      required: true,
    },
    onClickDownloadErrors: {
      type: Function,
      default: () => ({}),
    },
  },
  created() {
    this.contentCells = errorsContentCells;
  },
};
</script>
<template>
  <div
    class="
      emobg-background-color-ground-lightest emobg-border-1
      emobg-border-color-ink-lighter p-3 emobg-border-radius-small h-100
    "
  >
    <h5 class="mb-1 emobg-font-weight-semibold">
      Here's the processing summary of {{ numberOfEmployees }} employees
    </h5>
    <p class="emobg-font-weight-light mb-2 emobg-font-x-small">
      {{ fileName }}
    </p>
    <div class="mb-3 d-flex align-items-center">
      <ui-icon
        :icon="ICONS.alertFull"
        :size="ICONS_SIZES.xLarge"
        :color="COLORS.danger"
        class="mr-2"
      />
      <p class="emobg-color-danger emobg-caption-2">
        {{ errors.length }} employees have errors
      </p>
    </div>
    <ui-alert
      :color="COLORS.danger"
      class="w-100 animated fadeIn pb-3"
    >
      <div class="d-flex align-items-center">
        <div>
          <p class="emobg-font-weight-semibold emobg-font-line-height-large">
            We cannot import this file
          </p>
          <p class="emobg-font-line-height-large">
            Correct the errors first and then upload the file again.
          </p>
          <p class="emobg-font-line-height-large">
            You can download the XSLX file with the errors below.
          </p>
        </div>
      </div>
    </ui-alert>
    <div class="w-100 d-flex justify-content-center">
      <MuiTable
        :content-cells="contentCells"
        :data-set="errors"
        :no-data-label="FALLBACK_MESSAGE.dash"
        data-test-id="import_employee_error-table"
        class="ImportEmployeesModal__errorTable w-100 mt-1"
      />
    </div>
    <div class="d-flex my-3">
      <ui-button
        :face="FACES.outline"
        class="mr-3"
        @clickbutton="onClickDownloadErrors"
      >
        Download XSLX with errors
      </ui-button>
      <ui-button @clickbutton="onUploadAgainClick">
        <div class="d-flex align-items-center">
          Upload again
          <ui-icon
            class="ml-2"
            :icon="ICONS.upload"
            :size="ICONS_SIZES.medium"
          />
        </div>
      </ui-button>
    </div>
    <p class="emobg-caption-1 mb-1">
      <b class="emobg-caption-2">
        If uploaded file has NO errors:
      </b>
      All employees get immediately created &amp; notified.
    </p>
    <p class="emobg-caption-1">
      <b class="emobg-caption-2">
        If uploaded file has errors:
      </b> No employees get created at all. Errors will be listed.
    </p>
  </div>
</template>
