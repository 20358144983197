<script>
import { MuiAlgoliaSelect, MuiModal } from '@emobg/motion-ui/v1';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, SaveButton } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  components: {
    MuiModal,
    MuiAlgoliaSelect,
    CancelButton,
    SaveButton,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userId: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.employees, {
      employeeStatus: state => state.employees.STATUS,
    }),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.modalConfig = {
      title: this.$t('CRM.Companies.Employees.add.title'),
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.employees, [
      'postEmployee',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async addEmployee() {
      await this.postEmployee({
        companyId: this.companyId,
        data: {
          userId: this.userId,
        },
      });
      if (!this.employeeStatus.ERROR) {
        this.notify({ message: this.$t('CRM.Companies.Employees.add.successMessage') });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <MuiModal
    v-bind="modalConfig"
    is-open
    data-test-id="add_employee-modal"
    @modal-closed="$emit('close-modal')"
  >
    <div slot="body">
      <MuiAlgoliaSelect
        v-model="userId"
        name="users"
        :index="ALGOLIA_INDEXES.users"
        :title="result => `${result.first_name} ${result.last_name} - ${result.email}`"
        :label="$t('CRM.Companies.Employees.add.inputLabel')"
        placeholder="Select user"
        class="w-100"
        path-value="id"
        data-test-id="user_id-select"
      />
    </div>
    <div
      slot="footer"
      class="d-flex align-content-center justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <SaveButton
        :disabled="!userId"
        :loading="employeeStatus.LOADING"
        data-test-id="save-button"
        @click="addEmployee"
      >
        Add
      </SaveButton>
    </div>
  </MuiModal>
</template>
